<template>
    <div class="profile-details">
        <div class="profile-details-heading">
            <h6 class="profile-details-title">{{ title }}</h6>
            <a :href="link" v-if="link">{{ linkText }}</a>
        </div>
        <slot>
            <profile-details-item
                v-for="(item, index) in items"
                :key="index"
                :label="item.label"
                :text="item.text"
            />
        </slot>
    </div>
</template>

<script>
    import ProfileDetailsItem from "../ProfileDetailsItem";

    export default {
        name: 'ProfileDetails',
        components: {
            ProfileDetailsItem
        },
        props: {
            title: {
                type: String,
                required: true
            },
            link: String,
            linkText: {
                type: String,
                default: 'Change'
            },
            items: {
                type: Array,
                default() {
                    return [];
                }
            }
        }
    }
</script>
