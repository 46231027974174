<template>
    <div class="profile-details-item">
        <p class="profile-details-label">{{ label }}</p>
        <p class="profile-details-help-text" v-if="helpText">{{ helpText }}</p>
        <div class="profile-details-text">
            <slot>{{ text }}</slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProfileDetailsItem',
        props: {
            label: {
                type: String,
                required: true
            },
            helpText: String,
            text: String,
        }
    }
</script>
