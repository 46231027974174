<template>
    <div class="container-fluid px-0">
        <ol class="page-breadcrumb breadcrumb">
            <li class="breadcrumb-item"><a href="#">Dashboard</a></li>
            <li class="breadcrumb-item active" aria-current="page">Profile</li>
        </ol>
        <div class="row">
            <div class="col">
                <div class="card card-rounded-lg">
                    <div class="card-body">
                        <h6 class="card-title">My Profile</h6>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea</p>
                        <profile-details title="Company Info" link="#" class="profile-details-container">
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <profile-details-item label="Company Name" text="Deliver America" />
                                    <profile-details-item
                                        label="Primary Contact Name"
                                        help-text="A unique name that is displayed to customers in the system."
                                        text="Name Surname" />
                                </div>
                                <div class="col-12 col-lg-6">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <profile-details-item
                                                label="Company Description"
                                                text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias commodi, consectetur dolore eum eveniet excepturi explicabo facilis fuga ipsam minima molestias nam nobis porro quasi qui quis, vel veniam voluptatibus."
                                            />
                                        </div>
                                        <div class="col-auto">
                                            <img src="/assets/images/avatar.png" class="avatar-lg rounded-lg" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </profile-details>
                        <div class="profile-details-container">
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <profile-details title="Address" link="#">
                                        <profile-details-item label="Company Address">
                                            <p class="mb-1">Address Line 1</p>
                                            <p class="mb-1">Address Line 2</p>
                                            <p class="mb-1">City</p>
                                            <p class="mb-1">State - Zipcode</p>
                                        </profile-details-item>
                                    </profile-details>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <profile-details title="Contact Details" link="#">
                                        <div class="row">
                                            <div class="col-12 col-md-6" v-for="(contact, index) in contacts" :key="index">
                                                <profile-details-item
                                                    :label="contact.label"
                                                    :text="contact.text"
                                                />
                                            </div>
                                        </div>
                                    </profile-details>
                                </div>
                            </div>
                        </div>
                        <div class="profile-details-container border-0 mb-0 pb-0">
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <profile-details title="Social Media" link="#">
                                        <div class="row">
                                            <div class="col-12 col-md-6" v-for="(social, index) in socials" :key="index">
                                                <profile-details-item
                                                    :label="social.label"
                                                    :text="social.text"
                                                />
                                            </div>
                                        </div>
                                    </profile-details>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <profile-details
                                        title="Rating & Reviews"
                                        link="#"
                                        link-text="View"
                                    >
                                        <div class="row">
                                            <div class="col-12 col-md-6">
                                                <profile-details-item label="Current Rating">
                                                    <star-rating rating="3" />
                                                </profile-details-item>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <profile-details-item label="Completed Reviews" text="2" />
                                            </div>
                                        </div>
                                    </profile-details>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ProfileDetails from "../../components/ProfileDetails";
    import ProfileDetailsItem from "../../components/ProfileDetailsItem";
    import StarRating from "../../components/StarRating";

    export default {
        name: 'ProfileLayout',
        components: {
            StarRating,
            ProfileDetailsItem,
            ProfileDetails
        },
        data() {
            return {
                contacts: [
                    {label: 'Contact Number', text: '555 555 5555'},
                    {label: 'Direct Contact Number', text: '555 555 5555'},
                    {label: 'Email Address', text: 'Info@domain.com'},
                    {label: 'Website Address', text: 'www.Domain.com'},
                ],
                socials: [
                    {label: 'Facebook', text: 'www.Facebook.com/zhctqlng'},
                    {label: 'Twitter', text: 'www.twitter.com/zhctqlng'},
                    {label: 'Instagram', text: 'www.instagram.com/zhctqlng'},
                    {label: 'Linkedin', text: 'www.linkedin.com/zhctqlng'},
                ]
            }
        }
    }
</script>
